var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"form"},[_c('b-form-group',{attrs:{"label":"Accion"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{attrs:{"options":[
						{ text: 'Matar', value: 1 },
						{ text: 'Depurar', value: 2 } ]},model:{value:(_vm.action),callback:function ($$v) {_vm.action=$$v},expression:"action"}}),(errors.length > 0)?_c('small',{staticClass:"text-center"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1),_c('b-table-simple',{staticClass:"mt-2 table-bordered",attrs:{"small":"","sticky-header":"80vh"}},[_c('b-thead',{staticClass:"text-center"},[_c('b-tr',[_c('b-th',[_vm._v("Cintillo")]),_c('b-th',[_vm._v("Observacion")]),_c('b-th',[_vm._v("Evidencia")]),_c('b-th',[_vm._v("Acciones")])],1)],1),_c('b-tbody',{staticClass:"text-center"},[_vm._l((_vm.rows),function(row,idx){return _c('b-tr',{key:idx},[_c('b-td',[_c('ValidationProvider',{staticClass:"text-center",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
					var errors = ref.errors;
return [_c('v-select',{staticClass:"mx-auto",class:{ 'border-danger rounded': !!errors[0] },staticStyle:{"width":"12rem"},attrs:{"label":"correlative","clearable":false,"options":_vm.cintilloOpts,"reduce":function (opt) { return opt.id; },"selectable":_vm.checkOptionSelectable,"appendToBody":"","calculatePosition":_vm.positionDropdown},on:{"search":_vm.fetchOpts,"option:selecting":_vm.handleCintilloSelected},model:{value:(row.cintillo),callback:function ($$v) {_vm.$set(row, "cintillo", $$v)},expression:"row.cintillo"}})]}}],null,true)})],1),_c('b-td',[(_vm.action == 1)?_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
					var errors = ref.errors;
return [_c('v-select',{staticClass:"mx-auto",class:{ 'border-danger rounded': !!errors[0] },staticStyle:{"width":"16rem"},attrs:{"label":"reason","options":_vm.reasonOpts,"clearable":false,"reduce":function (option) { return option.id; },"appendToBody":"","calculatePosition":_vm.positionDropdown},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('div',{staticClass:"d-flex justify-content-between"},[_c('span',{class:{ 'pl-2': !!option.parent_id }},[_vm._v(" "+_vm._s(option.reason)+" ")]),(option.parent_id)?_c('span',[_vm._v(" Sub-motivo de "+_vm._s(option.parent_reason)+" ")]):_vm._e()])]}}],null,true),model:{value:(row.observacion),callback:function ($$v) {_vm.$set(row, "observacion", $$v)},expression:"row.observacion"}})]}}],null,true)}):(_vm.action == 2)?_c('b-form-textarea',{staticStyle:{"min-width":"12rem"},attrs:{"no-resize":""},model:{value:(row.comentario),callback:function ($$v) {_vm.$set(row, "comentario", $$v)},expression:"row.comentario"}}):_vm._e()],1),_c('b-td',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
					var errors = ref.errors;
return [(!row.useEvidence)?_c('b-button',{staticClass:"btn-icon",class:{ 'border-danger rounded': !!errors[0] },attrs:{"variant":"flat-secondary"},on:{"click":function($event){row.useEvidence = true}}},[_c('feather-icon',{attrs:{"icon":"PlusIcon","size":"20"}})],1):_c('div',{staticClass:"d-flex justify-content-center align-items-center gap-x-2",staticStyle:{"min-width":"12rem"}},[_c('ShowAndUploadImg',{model:{value:(row.evidencia),callback:function ($$v) {_vm.$set(row, "evidencia", $$v)},expression:"row.evidencia"}}),_c('b-button',{staticClass:"btn-icon",attrs:{"variant":"flat-danger"},on:{"click":function($event){return _vm.hideEvidence(idx)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon","size":"20"},on:{"click":function($event){row.useEvidence = true}}})],1)],1)]}}],null,true)})],1),_c('b-td',[_c('b-button',{staticClass:"btn-icon",attrs:{"size":"sm","variant":"danger","disabled":_vm.rows.length < 2},on:{"click":function($event){return _vm.deleteRow(idx)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}})],1)],1)],1)}),(_vm.action == null)?_c('b-tr',[_c('b-td',{staticClass:"text-center",attrs:{"colspan":"5"}},[_vm._v("Seleccione una accion para continuar")])],1):_vm._e()],2)],1),_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{staticClass:"btn-icon",attrs:{"size":"sm","variant":"outline-success"},on:{"click":_vm.addRow}},[_c('feather-icon',{attrs:{"icon":"PlusIcon","size":"20"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }