var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"form"},[_c('b-table-simple',{staticClass:"mt-2 table-bordered",attrs:{"small":"","sticky-header":"80vh"}},[_c('b-thead',{staticClass:"text-center"},[_c('b-tr',[_c('b-th',[_vm._v("Ejemplar")]),_c('b-th',[_vm._v("Razon")]),_c('b-th',[_vm._v("Fecha")]),_c('b-th',[_vm._v("Evidencia")]),_c('b-th',[_vm._v("Acciones")])],1)],1),_c('b-tbody',{staticClass:"text-center"},_vm._l((_vm.rows),function(row,idx){return _c('b-tr',{key:idx},[_c('b-td',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{ 'border-danger rounded': !!errors[0] },staticStyle:{"min-width":"15rem"},attrs:{"label":"query","clearable":false,"options":_vm.specimenOpts,"reduce":_vm.specimenReducer,"selectable":_vm.checkOptionSelectable,"getOptionKey":function (opt) { return ((opt.id) + "-" + (opt.chick_id)); },"appendToBody":"","calculatePosition":_vm.positionDropdown},on:{"search":_vm.fetchOpts,"option:selecting":_vm.handleSpecimenSelected},scopedSlots:_vm._u([{key:"option",fn:function(opt){return [_c('span',[_vm._v(_vm._s(opt.alias || "---")+" / ("+_vm._s(opt.plate)+")")])]}},{key:"selected-option",fn:function(opt){return [_c('span',[_vm._v(_vm._s(opt.alias || "---")+" / ("+_vm._s(opt.plate)+")")])]}}],null,true),model:{value:(row.specimen),callback:function ($$v) {_vm.$set(row, "specimen", $$v)},expression:"row.specimen"}})]}}],null,true)})],1),_c('b-td',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{ 'border-danger rounded': !!errors[0] },staticStyle:{"min-width":"15rem"},attrs:{"label":"reason","options":_vm.reasonOpts,"clearable":false,"reduce":function (option) { return option.id; },"appendToBody":"","calculatePosition":_vm.positionDropdown},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('div',{staticClass:"d-flex justify-content-between"},[_c('span',{class:{ 'pl-2': !!option.parent_id }},[_vm._v(" "+_vm._s(option.reason)+" ")]),(option.parent_id)?_c('span',[_vm._v(" Sub-motivo de "+_vm._s(option.parent_reason)+" ")]):_vm._e()])]}}],null,true),model:{value:(row.reason),callback:function ($$v) {_vm.$set(row, "reason", $$v)},expression:"row.reason"}})]}}],null,true)})],1),_c('b-td',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control bg-transparent",class:{ 'border-danger is-invalid': !!errors[0] },staticStyle:{"min-width":"10rem"},model:{value:(row.date),callback:function ($$v) {_vm.$set(row, "date", $$v)},expression:"row.date"}}),(errors.length != 0)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('b-td',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(!row.useEvidence)?_c('b-button',{staticClass:"btn-icon",class:{ 'border-danger rounded': !!errors[0] },attrs:{"variant":"flat-secondary"},on:{"click":function($event){row.useEvidence = true}}},[_c('feather-icon',{attrs:{"icon":"PlusIcon","size":"20"}})],1):_c('div',{staticClass:"d-flex justify-content-center align-items-center gap-x-2",staticStyle:{"min-width":"10rem"}},[_c('ShowAndUploadImg',{model:{value:(row.evidence),callback:function ($$v) {_vm.$set(row, "evidence", $$v)},expression:"row.evidence"}}),_c('b-button',{staticClass:"btn-icon",attrs:{"variant":"flat-danger"},on:{"click":function($event){return _vm.hideEvidence(idx)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon","size":"20"},on:{"click":function($event){row.useEvidence = true}}})],1)],1)]}}],null,true)})],1),_c('b-td',[_c('b-button',{staticClass:"btn-icon",attrs:{"size":"sm","variant":"danger","disabled":_vm.rows.length < 2},on:{"click":function($event){return _vm.deleteRow(idx)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}})],1)],1)],1)}),1)],1),_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{staticClass:"btn-icon",attrs:{"size":"sm","variant":"outline-success"},on:{"click":_vm.addRow}},[_c('feather-icon',{attrs:{"icon":"PlusIcon","size":"20"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }