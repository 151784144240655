<template>
	<div>
		<ValidationObserver ref="form">
			<b-form-group label="Accion">
				<ValidationProvider rules="required" v-slot="{ errors }">
					<b-form-radio-group
						v-model="action"
						:options="[
							{ text: 'Matar', value: 1 },
							{ text: 'Depurar', value: 2 },
						]"
					/>
					<small class="text-center" v-if="errors.length > 0">{{ errors[0] }}</small>
				</ValidationProvider>
			</b-form-group>
			<b-table-simple class="mt-2 table-bordered" small sticky-header="80vh">
				<b-thead class="text-center">
					<b-tr>
						<b-th>Cintillo</b-th>
						<b-th>Observacion</b-th>
						<b-th>Evidencia</b-th>
						<b-th>Acciones</b-th>
					</b-tr>
				</b-thead>
				<b-tbody class="text-center">
					<b-tr v-for="(row, idx) in rows" :key="idx">
						<b-td>
							<ValidationProvider class="text-center" rules="required" v-slot="{ errors }">
								<v-select
									label="correlative"
									style="width: 12rem"
									class="mx-auto"
									:class="{ 'border-danger rounded': !!errors[0] }"
									:clearable="false"
									:options="cintilloOpts"
									:reduce="(opt) => opt.id"
									:selectable="checkOptionSelectable"
									v-model="row.cintillo"
									appendToBody
									:calculatePosition="positionDropdown"
									@search="fetchOpts"
									@option:selecting="handleCintilloSelected"
								/>
							</ValidationProvider>
						</b-td>
						<b-td>
							<ValidationProvider rules="required" v-slot="{ errors }" v-if="action == 1">
								<v-select
									label="reason"
									style="width: 16rem"
									class="mx-auto"
									:class="{ 'border-danger rounded': !!errors[0] }"
									:options="reasonOpts"
									:clearable="false"
									:reduce="(option) => option.id"
									v-model="row.observacion"
									appendToBody
									:calculatePosition="positionDropdown"
								>
									<template v-slot:option="option">
										<div class="d-flex justify-content-between">
											<span :class="{ 'pl-2': !!option.parent_id }">
												{{ option.reason }}
											</span>
											<span v-if="option.parent_id">
												Sub-motivo de {{ option.parent_reason }}
											</span>
										</div>
									</template>
								</v-select>
							</ValidationProvider>
							<b-form-textarea
								style="min-width: 12rem"
								no-resize
								v-model="row.comentario"
								v-else-if="action == 2"
							/>
						</b-td>
						<b-td>
							<ValidationProvider rules="required" v-slot="{ errors }">
								<b-button
									class="btn-icon"
									:class="{ 'border-danger rounded': !!errors[0] }"
									variant="flat-secondary"
									@click="row.useEvidence = true"
									v-if="!row.useEvidence"
								>
									<feather-icon icon="PlusIcon" size="20" />
								</b-button>
								<div
									class="d-flex justify-content-center align-items-center gap-x-2"
									style="min-width: 12rem"
									v-else
								>
									<ShowAndUploadImg v-model="row.evidencia" />
									<b-button class="btn-icon" variant="flat-danger" @click="hideEvidence(idx)">
										<feather-icon icon="TrashIcon" size="20" @click="row.useEvidence = true" />
									</b-button>
								</div>
							</ValidationProvider>
						</b-td>
						<b-td>
							<!-- <pre>{{ row }}</pre> -->
							<b-button
								class="btn-icon"
								size="sm"
								variant="danger"
								@click="deleteRow(idx)"
								:disabled="rows.length < 2"
							>
								<feather-icon icon="TrashIcon" />
							</b-button>
						</b-td>
					</b-tr>
					<b-tr v-if="action == null">
						<b-td class="text-center" colspan="5">Seleccione una accion para continuar</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
			<div class="d-flex justify-content-end">
				<b-button class="btn-icon" size="sm" variant="outline-success" @click="addRow">
					<feather-icon icon="PlusIcon" size="20" />
				</b-button>
			</div>
		</ValidationObserver>
	</div>
</template>

<script>
import { getBase64 } from "@/helpers/functions"
import deadsService from "@/services/deads.service"
import reasonsService from "@/services/reasons.service"

import ShowAndUploadImg from "./ShowAndUploadImg.vue"

export default {
	components: { ShowAndUploadImg },
	props: {
		saveTrigger: Symbol,
	},
	data: () => ({
		action: 1,
		rows: [
			{
				cintillo: null,
				observacion: null,
				comentario: null,
				useEvidence: false,
				evidencia: [],
			},
		],
		cintilloOpts: [],
		selectedCintillos: [],
		reasonOpts: [],
	}),
	methods: {
		async getOpts() {
			this.$emit("loading", true)
			const reasons = await reasonsService.getByModule()
			this.reasonOpts = reasons
			this.$emit("loading", false)
		},
		resetAll() {
			this.action = 1
			this.rows = [
				{
					cintillo: null,
					observacion: null,
					comentario: null,
					useEvidence: false,
					evidencia: [],
				},
			]
			this.cintilloOpts = []
			this.selectedCintillos = []
			this.$refs.form.reset()
		},
		addRow() {
			this.rows.push({
				cintillo: null,
				observacion: null,
				comentario: null,
				useEvidence: false,
				evidencia: [],
			})
		},
		deleteRow(idx) {
			this.rows.splice(idx, 1)
		},
		positionDropdown(dropdownList, component, { width, top, left }) {
			dropdownList.style.zIndex = 9999
			dropdownList.style.maxHeight = "20rem"
			dropdownList.style.top = top
			dropdownList.style.left = left
			dropdownList.style.width = width
		},
		// search
		fetchOpts(search, loading) {
			this.findCintillos(search, loading, this.setOpts)
		},
		findCintillos: _.debounce(async (search, loading, setter) => {
			loading(true)
			const { data } = await deadsService.getChicks({ page: 1, perpage: 50, search })
			setter(data)
			loading(false)
		}, 500),
		setOpts(data) {
			const existingIds = this.selectedCintillos.map((opt) => opt.id)
			const withoutDuplicateds = data.filter((opt) => !existingIds.includes(opt.id))
			this.cintilloOpts = [...withoutDuplicateds, ...this.selectedCintillos]
		},
		handleCintilloSelected(cintillo) {
			const existingIds = this.selectedCintillos.map((opt) => opt.id)
			if (existingIds.includes(cintillo.id)) return
			this.selectedCintillos.push(cintillo)
		},
		async hideEvidence(idx) {
			const row = this.rows[idx]
			if (row.evidencia.length != 0) {
				const { isConfirmed } = await this.showConfirmSwal({
					text: `Se borraran las imagenes de evidencia seleccionadas`,
				})
				if (!isConfirmed) return
				row.evidencia = []
			}
			row.useEvidence = false
		},
		async save() {
			if (!(await this.$refs.form.validate())) return
			const { isConfirmed } = await this.showConfirmSwal({
				title: `Se registrara ${this.rows.length} cintillo(s) en ala como ${
					this.action == 1 ? "MUERTO" : "DEPURADO"
				}`,
			})
			if (!isConfirmed) return
			this.$emit("loading", true)
			const cintillos = []
			for (let i = 0; i < this.rows.length; i++) {
				const row = this.rows[i]

				// images
				let images = []
				for (let idx = 0; idx < row.evidencia.length; idx++) {
					const img = row.evidencia[idx]
					let base = undefined
					if (img.id == null) {
						base = await getBase64(img.file)
					}
					images.push({ base })
				}
				//
				cintillos.push({
					id: row.cintillo,
					status_id: this.action == 1 ? 3 : 4,
					reason_id: this.action == 1 ? row.observacion : undefined,
					observation: this.action == 2 ? row.comentario : undefined,
					images,
				})
			}
			await deadsService.insertDeadAla({ chicks: cintillos })
			this.showToast("success", "top-right", "Muertos", "CheckIcon", "Guardado con exito")
			this.resetAll()
			this.$emit("loading", false)
		},
		checkOptionSelectable(opt) {
			const existingIds = this.rows.map((row) => row.cintillo)
			if (existingIds.includes(opt.id)) return false
			return true
		},
	},
	mounted() {
		this.getOpts()
	},
	watch: {
		saveTrigger(val) {
			this.save()
		},
	},
}
</script>
