<template>
	<b-modal
		ref="chick-depuration-request-modal"
		:title="'Solicitudes de Depuración'"
		centered
		@hidden="handleHidden"
		size="xmd"
		hide-footer
	>
		<b-overlay :show="isLoading">
			<b-tabs>
				<b-tab @click="tab = 0" :active="tab === 0">
					<template #title>
						Pendientes
						<!-- <b-badge variant="danger" class="ml-1">{{ selectedMonth.pata_qty }}</b-badge> -->
					</template>
				</b-tab>
				<b-tab @click="tab = 1" :active="tab === 1">
					<template #title>
						Finalizadas
						<!-- <b-badge variant="danger" class="ml-1">{{ selectedMonth.ala_qty }}</b-badge> -->
					</template>
				</b-tab>
			</b-tabs>
			<div class="d-flex flex-column w-100">
				<b-row class="mb-1">
					<b-col class="d-flex align-items-center">
						<small class="text-secondary">
							Mostrando {{ pagination.from }} a {{ pagination.to }} de {{ pagination.total }} registros
						</small>
					</b-col>
					<b-col class="d-flex align-items-center justify-content-end flex-wrap gap-x-4 gap-y-1">
						<b-pagination
							v-model="pagination.curPage"
							:total-rows="pagination.total"
							:per-page="pagination.perPage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item"
						>
							<template #prev-text>
								<feather-icon icon="ChevronLeftIcon" size="18" />
							</template>
							<template #next-text>
								<feather-icon icon="ChevronRightIcon" size="18" />
							</template>
						</b-pagination>
					</b-col>
				</b-row>
				<b-row class="mb-1">
					<b-col cols="12" xl="7" class="d-flex align-items-center justify-content-start">
						<label>Mostrar</label>
						<v-select
							v-model="pagination.perPage"
							:options="perPageOptions"
							:clearable="false"
							class="per-page-selector d-inline-block mx-50"
							style="width: 90px"
							@input="() => getChicks()"
						/>
						<label class="mr-2">registros</label>
						<feather-icon class="cursor-pointer" icon="RefreshCcwIcon" size="20" @click="handleRefresh" />
					</b-col>
					<!-- <b-col cols="12" xl="5">
					<div class="container-btns-page-specimens">
						<div class="d-flex">
							<b-form-input
								v-model="search"
								class="d-inline-block mr-1"
								placeholder="Rival"
								@keyup.enter="handleInputSearch"
								debounce="500"
							/>
						</div>
					</div>
				</b-col> -->
				</b-row>
				<b-table-simple responsive sticky-header="60vh" no-border-collapse>
					<b-thead class="sticky-header">
						<b-th class="text-center">Código</b-th>
						<b-th class="text-center">Estado</b-th>
						<b-th class="text-center">Corral</b-th>
						<b-th class="text-center">Total</b-th>
						<b-th class="text-center">Depurados</b-th>
						<b-th class="text-center">Fecha</b-th>
					</b-thead>
					<b-tbody>
						<template v-if="requests.length > 0">
							<b-tr v-for="(request, index) in requests" :key="index">
								<b-td class="text-center">
									<span>{{ request.code }}</span>
								</b-td>
								<b-td class="text-center">
									<span>{{ request.status }}</span>
								</b-td>
								<b-td class="text-center">
									<div
										v-b-tooltip.hover
										title="Ver corral"
										class="cursor-pointer text-primary font-weight-bolder"
										@click="setCorral(request.corral)"
									>
										{{ request.corral.alias }}
									</div>
									<div>{{ request.corral.code }}</div>
								</b-td>
								<b-td class="text-center">
									<span
										class="cursor-pointer text-primary"
										style="font-weight: bold"
										@click="
											corral_id = request.corral.id
											showDepuration = true
											alias_corral = request.corral.alias
											code_corral = request.corral.code
											selected_request_id = request.id
										"
										v-if="request.total_chicks"
									>
										{{ request.total_chicks }}
									</span>
									<span v-else>---</span>
								</b-td>
								<b-td class="text-center">
									<span class="text-danger" style="font-weight: bold">
										{{ request.total_depured_chicks }}
									</span>
								</b-td>
								<b-td>
									<div class="d-flex flex-column justify-content-center w-100 align-items-center">
										<span>{{ request.request_date | myGlobalDateTime }}</span>
									</div>
								</b-td>
							</b-tr>
						</template>
						<b-tr v-if="requests.length == 0">
							<b-td colspan="10" class="text-center" style="height: 80px">
								No hay registros para mostrar
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>
			</div>
		</b-overlay>

		<ChickDepurationDetailsModal
			v-if="showDepuration"
			:corral_id="corral_id"
			:alias_corral="alias_corral"
			:code_corral="code_corral"
			:request_id="selected_request_id"
			@closing="showDepuration = false"
			@refresh="getChicks"
		/>
		<ViewChicksByCorral v-if="selectCorral" :corral="selectCorral" @refresh="refreshViewChicks" />
	</b-modal>
</template>

<script>
import modalMixin from "@/mixins/modal"
import ViewImage from "@/components/commons/ViewImage.vue"
import CorralService from "@/services/encaste/corral.service"
import DepurationFemaleModal from "@/views/amg/header-bookmarks/DepurationFemaleModal.vue"
import ChickDepurationDetailsModal from "./ChickDepurationDetailsModal.vue"
import { mapGetters, mapActions } from "vuex"
import ViewChicksByCorral from "@/views/amg/encaste/corrales/components/ViewChicksByCorral"
export default {
	components: { ViewImage, DepurationFemaleModal, ChickDepurationDetailsModal, ViewChicksByCorral },
	mixins: [modalMixin],
	props: {
		show: Boolean,
		info: Object,
	},
	data() {
		return {
			selectCorral: null,
			tab: 0,
			perPageOptions: [10, 25, 50],
			isLoading: false,
			requests: [],
			showDepuration: false,
			corral_id: null,
			alias_corral: null,
			code_corral: null,
			selected_request_id: null,
			pagination: {
				total: 0,
				from: 0,
				to: 0,
				curPage: 1,
				perPage: 25,
			},
		}
	},
	created() {
		if (this.isAdmin && this.G_DEPURATIONS_FOR_ADMIN > 0) {
			this.tab = 1
		}
		this.getChicks()
		if (this.isAdmin) {
			this.seenByAdmin()
		}
		if (this.isEncastador) {
			this.seenByEncastador()
		}
	},
	computed: {
		...mapGetters({
			G_DEPURATIONS_FOR_ADMIN: "commons/G_DEPURATIONS_FOR_ADMIN",
		}),
	},
	methods: {
		async refreshViewChicks() {
			this.selectCorral = null
			this.getChicks()
		},
		setCorral(corral) {
			this.selectCorral = corral
		},
		...mapActions({
			A_GET_DEPURATION_COUNTERS: "commons/A_GET_DEPURATION_COUNTERS",
		}),
		handleRefresh() {
			this.getChicks()
		},
		handleHidden() {
			this.isLoading = false
			this.$emit("closing")
		},

		async getChicks(page = 1) {
			this.isPreloading()

			try {
				const params = {
					per_page: this.pagination.perPage,
					page: page,
					status: this.tab === 0 ? "PENDIENTE" : "FINALIZADA",
				}
				const { data } = await CorralService.getDepurationRequests(params)
				this.requests = data.data
				this.pagination.curPage = data.current_page
				this.pagination.total = data.total
				this.pagination.from = data.from
				this.pagination.to = data.to
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},
		async seenByAdmin() {
			try {
				await CorralService.seenByAdmin()
				await this.A_GET_DEPURATION_COUNTERS()
			} catch (error) {
				console.log(error)
			}
		},
		async seenByEncastador() {
			try {
				await CorralService.seenByEncastador()
				await this.A_GET_DEPURATION_COUNTERS()
			} catch (error) {
				console.log(error)
			}
		},
	},
	watch: {
		tab() {
			this.getChicks()
		},
		"pagination.curPage": async function () {
			await this.getChicks(this.pagination.curPage)
		},
	},
	async mounted() {
		this.toggleModal("chick-depuration-request-modal")
	},
}
</script>

<style lang="scss" scoped></style>
