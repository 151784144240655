<template>
	<div>
		<b-modal
			hide-footer
			:title="modalViewChicks.title"
			size="xl"
			@hidden="$emit('refresh')"
			v-model="modalViewChicks.show"
			scrollable
		>
			<validation-observer ref="form">
				<!-- <pre>{{ savedChicks }}</pre> -->
				<b-row>
					<b-col cols="12" md="8" class="d-flex">
						<b-row>
							<b-col class="d-flex flex-column">
								<p class="font-weight-bolder mb-50">FILTRAR:</p>
								<div class="d-flex justify-content-between align-items-center">
									<div class="d-flex w-100">
										<h2 class="mb-0 text-span-corrales mb-1">
											<b-badge
												variant="primary"
												size="lg"
												class="mr-1 mb-0"
												role="button"
												@click="filterAvailable"
												v-b-tooltip:hover.bottom="`Filtrar`"
											>
												Disponibles: {{ chicksLength }}
											</b-badge>
										</h2>
										<h2 class="mb-0 text-span-corrales mb-1">
											<b-badge
												variant="danger"
												size="lg"
												class="mb-0 mr-1"
												role="button"
												@click="filterDead"
												v-b-tooltip:hover.bottom="`Filtrar`"
											>
												Muertos: {{ corral.chicks_deads }}
											</b-badge>
										</h2>
										<h2 class="mb-0 text-span-corrales mb-1">
											<b-badge
												variant="warning"
												size="lg"
												class="mb-0 mr-1"
												role="button"
												@click="filterSick"
												v-b-tooltip:hover.bottom="`Filtrar`"
											>
												Enfermos: {{ corral.chicks_sick }}
											</b-badge>
										</h2>
										<h2 class="mb-0 text-span-corrales mb-1 mr-1">
											<b-badge size="lg" class="mb-0" role="button" @click="filterPlates">
												Placas: {{ specimensLength }}
											</b-badge>
										</h2>
										<b-dropdown variant="link" toggle-class="p-0 mb-1" no-caret>
											<template #button-content>
												<h2 class="text-span-corrales mb-0">
													<b-badge variant="info" size="lg" class="mb-0" role="button">
														{{ hembraDropdownName }}
														<FeatherIcon icon="ChevronDownIcon" />
													</b-badge>
												</h2>
											</template>

											<b-dropdown-item @click="hembrasFilter(2)">
												<span class="align-middle ml-50">= 2 hembras</span>
											</b-dropdown-item>

											<b-dropdown-item @click="hembrasFilter(3)">
												<span class="align-middle ml-50">= 3 hembras</span>
											</b-dropdown-item>

											<b-dropdown-item @click="hembrasFilter(4)">
												<span class="align-middle ml-50">>= 4 hembras</span>
											</b-dropdown-item>
										</b-dropdown>
										<h2
											v-if="filtersActive"
											@click="init(), (filtersActive = false)"
											class="cursor-pointer mb-0 text-span-corrales mb-1 ml-50 d-flex align-items-center"
										>
											<BroomSweepingSvg />
											<p class="ml-25 mb-0 font-small-4">Limpiar filtros</p>
										</h2>
									</div>
								</div>
							</b-col>
						</b-row>
					</b-col>
					<b-col cols="12" md="2" class="d-flex justify-content-end mt-1 mt-md-1">
						<div class="d-flex justify-content-end">
							<h2 class="text-span-corrales m-1">
								<b-badge size="lg" class="mb-0 badge-male">Machos: {{ machos_qty }}</b-badge>
							</h2>
							<h2 class="text-span-corrales m-1">
								<b-badge size="lg" class="mb-0 badge-female">Hembras: {{ hembras_qty }}</b-badge>
							</h2>
							<h2 class="text-span-corrales m-1">
								<b-badge size="lg" class="mb-0 badge-unassigned">
									Sin asignar: {{ sin_asignar_qty }}
								</b-badge>
							</h2>
						</div>
					</b-col>
					<b-col cols="12" md="2" class="d-flex justify-content-end mt-1 mt-md-1">
						<!-- AGREGAR POLLOS -->

						<CreateCorral
							style="max-width: 200px"
							class="create-corral"
							v-if="corral && corral.status != 'FINALIZADO'"
							:corral="corral"
							@refresh="init()"
						/>
					</b-col>
				</b-row>

				<b-row class="mt-2">
					<b-col cols="12" lg="3" class="mb-2">
						<ValidationProvider class="select-year" rules="required" v-slot="{ errors }">
							<div class="d-flex align-items-center">
								<b-form-group
									label="Fecha de inicio"
									:invalid-feedback="errors[0]"
									:state="errors[0] ? false : null"
									class="mb-0 w-100"
									label-class="font-small-4 text-secondary"
								>
									<flat-pickr
										:disabled="corral && corral.status == 'FINALIZADO'"
										v-model="date"
										class="form-control bg-white"
										placeholder="Seleccionar fechas"
										:class="errors[0] ? 'is-invalid' : ''"
										@on-change="changeDate"
									/>
								</b-form-group>
							</div>
						</ValidationProvider>
					</b-col>
					<b-col cols="12" lg="3" class="mb-2">
						<ValidationProvider class="select-year w-50" rules="required" v-slot="{ errors }">
							<div class="d-flex align-items-center ml-0 ml-lg-1">
								<b-form-group
									label="Alias"
									:invalid-feedback="errors[0]"
									class="mb-0 w-100"
									label-class="font-small-4 text-secondary"
								>
									<b-form-input
										:disabled="corral && corral.status == 'FINALIZADO'"
										@input="changeDate"
										:state="errors[0] ? false : null"
										v-model="alias"
										placeholder="Agregar alias"
									></b-form-input>
								</b-form-group>
							</div>
						</ValidationProvider>
					</b-col>
					<b-col cols="12" lg="6" class="mb-2">
						<b-form-group
							label="Comentario"
							label-for="comment"
							class="mb-0"
							label-class="font-small-4 text-secondary"
						>
							<b-form-textarea
								v-model="comment"
								placeholder="Agregue un comentario(opcional)"
								rows="2"
								max-rows="3"
								@input="changeDate"
								:disabled="corral && corral.status == 'FINALIZADO'"
							></b-form-textarea>
						</b-form-group>
					</b-col>
				</b-row>

				<div class="mb-1 d-flex justify-content-end">
					<b-button variant="primary" v-if="updateDate" @click="updateColumnsCorral">Actualizar</b-button>

					<div
						class="d-flex justify-content-end mt-2"
						v-if="corral.status != 'FINALIZADO' && chicksDeleted.length > 0"
					>
						<button @click="updateChicks" class="btn btn-primary">Actualizar pollos</button>
					</div>
				</div>

				<b-row class="mb-2">
					<b-col>
						<b-dropdown aria-colspan="" variant="primary" text="Acciones masivas" class="">
							<b-dropdown-item
								@click="retirement.start = true"
								v-if="!retirement.start && !changeCorral.selecting"
							>
								Iniciar agregar placa
							</b-dropdown-item>
							<template v-if="retirement.start">
								<b-dropdown-item @click="retirement.show = true">
									<span class="text-primary">
										<FeatherIcon icon="ArrowRightIcon" size="20" />
										Procesar agregar placa
									</span>
								</b-dropdown-item>
								<b-dropdown-item @click="cancelRetirement">
									<span class="text-danger">
										<FeatherIcon icon="XIcon" size="20" />
										Cancelar agregar placa
									</span>
								</b-dropdown-item>
							</template>

							<!--  -->
							<b-dropdown-item
								@click="changeCorral.selecting = true"
								v-if="!changeCorral.selecting && !retirement.start"
							>
								Iniciar cambiar corral
							</b-dropdown-item>
							<template v-if="changeCorral.selecting">
								<b-dropdown-item @click="processChangeCorral">
									<span class="text-primary">
										<FeatherIcon icon="ArrowRightIcon" size="20" />
										Procesar cambiar corral
									</span>
								</b-dropdown-item>
								<b-dropdown-item @click="cancelChangeCorral">
									<span class="text-danger">
										<FeatherIcon icon="XIcon" size="20" />
										Cancelar cambiar corral
									</span>
								</b-dropdown-item>
							</template>
							<!-- solo se muestra cuando no hay accion masiva realizandose -->
							<template v-if="!changeCorral.selecting && !retirement.start">
								<b-dropdown-item @click="applyDeworn">Aplicar desparacitacion</b-dropdown-item>
								<b-dropdown-item @click="applyVitaminsModal.show = !applyVitaminsModal.show">
									Aplicar suplemento
								</b-dropdown-item>
								<b-dropdown-item
									@click="removeAllChicks"
									v-if="
										chicksLength != 0 ||
										Number(corral.chicks_deads) != 0 ||
										Number(corral.chicks_sick) != 0
									"
								>
									Retirar pollos
								</b-dropdown-item>
							</template>
						</b-dropdown>
						<b-button
							v-if="!depuration.start && isAdmin"
							variant="primary"
							@click="depuration.start = true"
							class="mx-1"
						>
							Iniciar solicitud de depuración
						</b-button>
						<template v-if="depuration.start">
							<b-button @click="createDepurationRequest" variant="info" class="mx-1">
								<span class="text-white">
									<FeatherIcon icon="ArrowRightIcon" size="15" />
									Procesar solicitud de depuración
								</span>
							</b-button>
							<b-button @click="cancelDepuration" variant="danger" class="mx-1">
								<span class="text-white">
									<FeatherIcon icon="XIcon" size="15" />
									Cancelar solicitud de depuración
								</span>
							</b-button>
						</template>
					</b-col>
					<b-col class="d-flex justify-content-end gap-x-4">
						<div class="d-flex align-items-center gap-x-2">
							<b-button
								variant="warning"
								v-if="savedChicks.length > 0"
								@click="finalize"
								class="d-flex align-items-center"
							>
								<span style="white-space: nowrap">Finalizar acción</span>
								<b-badge variant="danger" class="ml-1">{{ savedChicks.length }}</b-badge>
							</b-button>
							<b-badge variant="primary">Nº Madrillas: {{ corral.count_madrillas }}</b-badge>
							<b-badge variant="primary">Nº Padrillos: {{ corral.count_padrillos }}</b-badge>
						</div>
						<b-input-group style="max-width: 30rem">
							<b-form-input
								type="search"
								class="search-input"
								@keyup.enter="init"
								v-model="search"
								placeholder="Cintillo"
							/>
							<b-input-group-append>
								<b-button @click="init" variant="primary">Buscar</b-button>
							</b-input-group-append>
						</b-input-group>
					</b-col>
				</b-row>

				<div class="table-responsive mb-0">
					<b-table-simple sticky-header="50vh" class="table mb-0">
						<b-thead>
							<b-tr>
								<b-th
									:class="[2, 3, 4].includes(key) ? 'z-20' : ''"
									v-for="(title, key) in headTable"
									:key="key"
								>
									<div class="d-flex align-items-center justify-content-center">
										<p class="mb-0">{{ title.title }}</p>
										<template v-if="['Padrillo', 'Madrilla'].includes(title.title)">
											<feather-icon
												class="cursor-pointer"
												icon="ChevronUpIcon"
												size="24"
												v-if="title.title == 'Padrillo' && !curSort.padrillo"
												@click="sortPosturas(1)"
											/>
											<feather-icon
												class="cursor-pointer"
												icon="ChevronDownIcon"
												size="24"
												v-if="title.title == 'Padrillo' && curSort.padrillo"
												@click="sortPosturas(2)"
											/>
											<feather-icon
												class="cursor-pointer"
												icon="ChevronDownIcon"
												size="24"
												v-if="title.title == 'Madrilla' && !curSort.madrilla"
												@click="sortPosturas(3)"
											/>
											<feather-icon
												class="cursor-pointer"
												icon="ChevronUpIcon"
												size="24"
												v-if="title.title == 'Madrilla' && curSort.madrilla"
												@click="sortPosturas(4)"
											/>
										</template>
										<template v-if="['machos', 'hembras', 'sin_asignar'].includes(title.key)">
											<feather-icon
												class="cursor-pointer text-warning ml-1"
												icon="ShuffleIcon"
												size="14"
												v-if="title.key == 'machos'"
												@click="openCorralSelector(title.key)"
												v-b-tooltip.hover.top="'Cambiar corral'"
											/>
											<feather-icon
												class="cursor-pointer text-warning ml-1"
												icon="ShuffleIcon"
												size="14"
												v-if="title.key == 'hembras'"
												@click="openCorralSelector(title.key)"
												v-b-tooltip.hover.top="'Cambiar corral'"
											/>
											<feather-icon
												class="cursor-pointer text-warning ml-1"
												icon="ShuffleIcon"
												size="14"
												v-if="title.key == 'sin_asignar'"
												@click="openCorralSelector(title.key)"
												v-b-tooltip.hover.top="'Cambiar corral'"
											/>
										</template>
									</div>
								</b-th>
							</b-tr>
						</b-thead>
						<b-tbody>
							<template v-if="posturas.length > 0">
								<b-tr class="text-center" v-for="(item, index) in posturas" :key="index">
									<b-td>
										<SpecimenPlate
											:specimen="{
												id: item.madrilla.id,
												plate: item.madrilla.plate,
												alias: item.madrilla.alias,
												thumb: item.madrilla.thumb,
												image: item.madrilla.image,
											}"
										/>
									</b-td>

									<b-td>
										<SpecimenPlate
											:specimen="{
												id: item.padrillo.id,
												plate: item.padrillo.plate,
												alias: item.padrillo.alias,
												thumb: item.padrillo.thumb,
												image: item.padrillo.image,
											}"
										/>
									</b-td>
									<b-td>
										<b-button
											variant="primary"
											size="sm"
											v-b-tooltip.hover.top="`Ver`"
											@click="toogleModalSiblings(item)"
										>
											<feather-icon icon="EyeIcon" />
										</b-button>
									</b-td>

									<b-td>
										<div :style="{ position: 'relative', width: '240px' }">
											<!--  -->
											<draggable
												:list="item.machos"
												:group="`group-chicks-${item.id}`"
												@change="changeChick($event, 'machos', index)"
												v-bind="dragOptions"
											>
												<transition-group class="chick-grid list-group-corrales">
													<div v-for="(it, key) in item.machos" :key="`${key}-chick-machos`">
														<template v-if="it.visible">
															<div
																class="chick-item"
																:class="[
																	it.status == 2 && !it.specimen_id
																		? 'bg-primary'
																		: '',
																	[3, 4].includes(it.status) && !it.specimen_id
																		? 'bg-dead'
																		: '',
																	it.specimen_id ? 'bg-success' : '',
																	it.status == 5 && !it.specimen_id
																		? 'bg-warning'
																		: '',
																	retirement.start || depuration.start
																		? 'cursor-pointer'
																		: 'cursor-move',
																	depuration.start && it.has_request
																		? 'cursor-not-allowed'
																		: '',
																	it.has_request && it.status != 4 ? 'bg-info' : '',
																]"
																v-b-tooltip.hover.top="
																	it.has_request && it.status != 4
																		? 'En proceso de depuración'
																		: ''
																"
																v-if="showMachos(it)"
																@click="handleChickPlateClick(it, index, 1)"
															>
																<p class="mb-0">{{ it.correlative }}</p>
																<div
																	v-if="
																		depuration.start &&
																		2 == it.status &&
																		!it.specimen_id &&
																		!it.has_request
																	"
																	class="circle-check-cintillo"
																>
																	<FeatherIcon
																		v-if="it.check"
																		icon="CheckIcon"
																		size="14"
																		class="text-white"
																	/>
																</div>
																<!-- radio agregar placas -->
																<div
																	v-if="
																		retirement.start &&
																		2 == it.status &&
																		!it.specimen_id
																	"
																	class="circle-check-cintillo"
																>
																	<FeatherIcon
																		v-if="it.check"
																		icon="CheckIcon"
																		size="14"
																		class="text-white"
																	/>
																</div>

																<div
																	v-if="
																		changeCorral.selecting &&
																		it.status != 3 &&
																		it.specimen_id == null
																	"
																	class="circle-check-cintillo"
																>
																	<FeatherIcon
																		v-if="it.check"
																		icon="CheckIcon"
																		size="14"
																		class="text-white"
																	/>
																</div>

																<div
																	v-if="
																		corral.status != 'FINALIZADO' &&
																		!it.specimen_id &&
																		!(
																			retirement.start &&
																			2 == it.status &&
																			!it.specimen_id
																		) &&
																		!changeCorral.selecting
																	"
																	class="close"
																	@click="removeChickenForCorral(it, 'machos', index)"
																>
																	<FeatherIcon icon="XIcon" class="icon" />
																</div>
																<div
																	class="tracking-corrales"
																	v-b-tooltip
																	title="Tracking corrales"
																	@click="
																		;(corralTracking.info = it),
																			(corralTracking.show = !corralTracking.show)
																	"
																	v-if="
																		it.counter_history_corrales > 1 &&
																		!retirement.start &&
																		!changeCorral.selecting
																	"
																>
																	<FeatherIcon class="text-white" icon="InfoIcon" />
																</div>
															</div>
														</template>
													</div>
												</transition-group>
											</draggable>
											<p v-if="item.machos.length == 0" class="mb-0 sin-chicks">Sin registros</p>
											<div
												class="bg-skeletor-reload-chicks"
												:class="[item.machos_reload ? 'active-skeletor' : '']"
											>
												<!-- <p class="mb-0 font-weight-bolder mb-25 font-small-2">Cargando...</p> -->
												<b-spinner variant="primary" label="Spinning"></b-spinner>
											</div>
										</div>
									</b-td>

									<b-td>
										<div :style="{ position: 'relative', width: '240px' }">
											<!-- -->
											<draggable
												:list="item.hembras"
												:group="`group-chicks-${item.id}`"
												@change="changeChick($event, 'hembras', index)"
												v-bind="dragOptions"
											>
												<transition-group
													class="chick-grid list-group-corrales"
													type="transition"
													:name="!drag ? 'flip-list' : null"
												>
													<div
														v-for="(it, key) in item.hembras"
														:key="`${key}-chick-hembras`"
													>
														<template v-if="it.visible">
															<div
																class="chick-item"
																:class="[
																	it.status == 2 && !it.specimen_id
																		? 'bg-primary'
																		: '',
																	[3, 4].includes(it.status) && !it.specimen_id
																		? 'bg-dead'
																		: '',
																	it.specimen_id ? 'bg-success' : '',
																	it.status == 5 && !it.specimen_id
																		? 'bg-warning'
																		: '',
																	retirement.start || depuration.start
																		? 'cursor-pointer'
																		: 'cursor-move',
																	existsInSaved(it.id) && 'bordered',
																	depuration.start && it.has_request
																		? 'cursor-not-allowed'
																		: '',
																	it.has_request && it.status != 4 ? 'bg-info' : '',
																]"
																v-b-tooltip.hover.top="
																	it.has_request && it.status != 4
																		? 'En proceso de depuración'
																		: ''
																"
																v-if="showHembras(it)"
																@click="handleChickPlateClick(it, index, 2)"
															>
																<p class="mb-0">{{ it.correlative }}</p>

																<div
																	v-if="
																		depuration.start &&
																		2 == it.status &&
																		!it.specimen_id &&
																		!it.has_request
																	"
																	class="circle-check-cintillo"
																>
																	<FeatherIcon
																		v-if="it.check"
																		icon="CheckIcon"
																		size="14"
																		class="text-white"
																	/>
																</div>

																<div
																	v-if="
																		retirement.start &&
																		2 == it.status &&
																		!it.specimen_id
																	"
																	class="circle-check-cintillo"
																>
																	<FeatherIcon
																		v-if="it.check"
																		icon="CheckIcon"
																		size="14"
																		class="text-white"
																	/>
																</div>
																<!-- radio cambiar corral -->
																<div
																	v-if="
																		changeCorral.selecting &&
																		it.status != 3 &&
																		it.specimen_id == null
																	"
																	class="circle-check-cintillo"
																>
																	<FeatherIcon
																		v-if="it.check"
																		icon="CheckIcon"
																		size="14"
																		class="text-white"
																	/>
																</div>
																<!--  -->

																<div
																	v-if="
																		corral.status != 'FINALIZADO' &&
																		!it.specimen_id &&
																		!(
																			retirement.start &&
																			2 == it.status &&
																			!it.specimen_id
																		) &&
																		!changeCorral.selecting
																	"
																	class="close"
																	@click="
																		removeChickenForCorral(it, 'hembras', index)
																	"
																>
																	<FeatherIcon icon="XIcon" class="icon" />
																</div>
																<div
																	class="tracking-corrales"
																	v-b-tooltip
																	title="Tracking corrales"
																	@click="
																		;(corralTracking.info = it),
																			(corralTracking.show = !corralTracking.show)
																	"
																	v-if="
																		it.counter_history_corrales > 1 &&
																		!retirement.start &&
																		!changeCorral.selecting
																	"
																>
																	<FeatherIcon class="text-white" icon="InfoIcon" />
																</div>
															</div>
														</template>
													</div>
												</transition-group>
											</draggable>
											<p v-if="item.hembras.length == 0" class="mb-0 sin-chicks">Sin registros</p>
											<div
												class="bg-skeletor-reload-chicks"
												:class="[item.hembras_reload ? 'active-skeletor' : '']"
											>
												<b-spinner variant="primary" label="Spinning"></b-spinner>
											</div>
										</div>
									</b-td>

									<b-td>
										<div :style="{ position: 'relative', width: '240px' }">
											<!--  -->

											<draggable
												:list="item.sin_asignar"
												:group="`group-chicks-${item.id}`"
												@change="changeChick($event, 'sin_asignar', index)"
												v-bind="dragOptions"
											>
												<transition-group
													class="chick-grid list-group-corrales"
													type="transition"
													:name="!drag ? 'flip-list' : null"
												>
													<div
														v-for="(it, key) in item.sin_asignar"
														:key="`${key}-chick-sin_asignar`"
													>
														<template v-if="it.visible">
															<div
																class="chick-item"
																:class="[
																	it.status == 2 && !it.specimen_id
																		? 'bg-primary'
																		: '',
																	[3, 4].includes(it.status) && !it.specimen_id
																		? 'bg-dead'
																		: '',
																	it.specimen_id ? 'bg-success' : '',
																	it.status == 5 && !it.specimen_id
																		? 'bg-warning'
																		: '',
																	retirement.start || depuration.start
																		? 'cursor-pointer'
																		: 'cursor-move',
																	depuration.start && it.has_request
																		? 'cursor-not-allowed'
																		: '',
																	it.has_request && it.status != 4 ? 'bg-info' : '',
																]"
																v-b-tooltip.hover.top="
																	it.has_request && it.status != 4
																		? 'En proceso de depuración'
																		: ''
																"
																v-if="!(changeCorral.selecting && it.status == 5)"
																@click="handleChickPlateClick(it, index, 3)"
															>
																<p class="mb-0">{{ it.correlative }}</p>

																<div
																	v-if="
																		depuration.start &&
																		2 == it.status &&
																		!it.specimen_id &&
																		!it.has_request
																	"
																	class="circle-check-cintillo"
																>
																	<FeatherIcon
																		v-if="it.check"
																		icon="CheckIcon"
																		size="14"
																		class="text-white"
																	/>
																</div>

																<div
																	v-if="changeCorral.selecting && it.status != 3"
																	class="circle-check-cintillo"
																>
																	<FeatherIcon
																		v-if="it.check"
																		icon="CheckIcon"
																		size="14"
																		class="text-white"
																	/>
																</div>

																<div
																	v-if="
																		corral.status != 'FINALIZADO' &&
																		!it.specimen_id &&
																		!changeCorral.selecting
																	"
																	class="close"
																	@click="
																		removeChickenForCorral(it, 'sin_asignar', index)
																	"
																>
																	<FeatherIcon icon="XIcon" class="icon" />
																</div>
																<div
																	class="tracking-corrales"
																	v-b-tooltip
																	title="Tracking corrales"
																	@click="
																		;(corralTracking.info = it),
																			(corralTracking.show = !corralTracking.show)
																	"
																	v-if="
																		it.counter_history_corrales > 1 &&
																		!retirement.start &&
																		!changeCorral.selecting
																	"
																>
																	<FeatherIcon class="text-white" icon="InfoIcon" />
																</div>
															</div>
														</template>
													</div>
												</transition-group>
											</draggable>
											<p v-if="item.sin_asignar.length == 0" class="mb-0 sin-chicks">
												Sin registros
											</p>
											<div
												class="bg-skeletor-reload-chicks"
												:class="[item.sin_asignar_reload ? 'active-skeletor' : '']"
											>
												<b-spinner variant="primary" label="Spinning"></b-spinner>
											</div>
										</div>
									</b-td>
								</b-tr>
							</template>
							<b-tr v-if="posturas.length === 0">
								<b-td colspan="10" class="text-center" style="height: 80px">
									No hay registros para mostrar
								</b-td>
							</b-tr>
						</b-tbody>
					</b-table-simple>
				</div>
			</validation-observer>
		</b-modal>

		<b-modal hide-footer scrollable size="lg" v-model="retirement.show" title="Agregar placa">
			<RetirementChicksByCorral
				v-if="retirement.show"
				:chicks="retirement.selects"
				:posturas="posturas"
				@deleteAddRetirementChick="deleteAddRetirementChick"
				@refresh="refreshRetirementChicks"
				:corral_id="corral.id"
				@addRetirementChick="addRetirementChick"
				is_corral_module
			/>
		</b-modal>
		<ModalListSiblings
			:show="showModalSiblings"
			:parents="modalSiblingParents"
			@closing="showModalSiblings = false"
			:corral_id="corral.id"
			:corral_code="corral.code"
		/>
		<ChangeCorralModal
			:show="changeCorral.show"
			:info="changeCorral.info"
			@closing="changeCorral.show = false"
			@refresh="init"
		/>
		<ClinicalActionsModal
			:show="applyVitaminsModal.show"
			:info="corral"
			@closing="applyVitaminsModal.show = false"
		/>
		<CorralTrackingModal
			:show="corralTracking.show"
			:info="corralTracking.info"
			@closing="corralTracking.show = false"
		/>
		<CorralSelectorModal
			v-if="showCorralSelector"
			:corralInfo="{ id: corral.id, name: modalViewChicks.title }"
			:sex="sex"
			@hidden="showCorralSelector = false"
			@changeCorralByType="changeCorralByType"
		/>
	</div>
</template>

<script>
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import CreateCorral from "../modals/CreateCorral.vue"
import draggable from "vuedraggable"
import chicksService from "@/services/encaste/chicks.service"
import CorralService from "@/services/encaste/corral.service"
import RetirementChicksByCorral from "./RetirementChicksByCorral.vue"
import ModalListSiblings from "./ModalListSiblings.vue"
import ChangeCorralModal from "@/views/amg/encaste/corrales/components/ChangeCorralModal.vue"
import ClinicalActionsModal from "./ClinicalActionsModal.vue"
import CorralTrackingModal from "./CorralTrackingModal.vue"
import CorralSelectorModal from "../modals/CorralSelectorModal.vue"
import BroomSweepingSvg from "@/icons/BroomSweepingSvg.vue"
export default {
	name: "ViewChicksByCorral",
	components: {
		SpecimenPlate,
		CreateCorral,
		draggable,
		RetirementChicksByCorral,
		ModalListSiblings,
		ChangeCorralModal,
		ClinicalActionsModal,
		CorralTrackingModal,
		CorralSelectorModal,
		BroomSweepingSvg,
	},
	props: {
		corral: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			hembraDropdownName: "Por # de hembras",
			sex: null,
			showCorralSelector: false,
			posturas: [],
			modalViewChicks: {
				show: true,
				title: "Agregar corral",
				size: "md",
			},
			headTable: [
				{ key: "madrilla", title: "Madrilla" },
				{ key: "padrillo", title: "Padrillo" },
				{ key: "actions", title: "Hijos" },
				{ key: "machos", title: "Machos" },
				{ key: "hembras", title: "Hembras" },
				{ key: "sin_asignar", title: "Sin asignar" },
			],
			chicksDeleted: [],
			date: this.corral.start_date,
			updateDate: false,
			comment: null,
			alias: null,
			chicksLength: 0,
			drag: false,
			reloadwidthWindow: null,
			specimensLength: 0,
			retirement: {
				start: false,
				show: false,
				selects: [],
			},
			depuration: {
				start: false,
				show: false,
				selecteds: [],
			},
			search: null,
			filtersActive: false,
			curSort: {
				// true when desc
				padrillo: true,
				madrilla: true,
			},
			showModalSiblings: false,
			modalSiblingParents: {},
			changeCorral: { show: false, info: {}, partial: [], selecting: false },
			applyVitaminsModal: { show: false },
			corralTracking: { show: false, info: {} },
			savedChicks: [],
			sexName: {
				machos: "MACHOS",
				hembras: "HEMBRAS",
				sin_asignar: "SIN ASIGNAR",
			},
			machos_qty: 0,
			hembras_qty: 0,
			sin_asignar_qty: 0,
		}
	},
	computed: {
		widthWindow() {
			return this.$store.state.app.windowWidth
		},
		dragOptions() {
			return {
				animation: 0,
				group: "description",
				disabled: this.retirement.start || this.changeCorral.selecting,
				ghostClass: "ghost",
			}
		},
	},
	async mounted() {
		// await this.init()
		// Se usa el filtro para al principio solo cargar los disponibles
		this.filterAvailable()
		this.updateDate = false
		this.comment = this.corral.comment
		this.alias = this.corral.alias
	},
	methods: {
		async createDepurationRequest() {
			const total_chicks = this.depuration.selecteds.length
			const correlative_selecteds = this.depuration.selecteds.map((item) => item.correlative).join(", ")
			if (total_chicks == 0) {
				this.showToast("warning", "top-right", "Corrales", "InfoIcon", "No hay pollos para depurar")
				return
			}
			const { isConfirmed } = await this.showConfirmSwal({
				title: "¿Estás seguro de depurar estos pollos?",
				html: `
						<div>
						<p><strong>Cantidad:</strong> ${total_chicks}</p>
						<p><strong>Cintillos:</strong> ${correlative_selecteds}</p>
						</div>
					`,
			})
			if (!isConfirmed) return
			this.isPreloading(true)
			try {
				await CorralService.createDepurationRequest({
					corral_id: this.corral.id,
					chicks: this.depuration.selecteds,
				})
				await this.filterAvailable()
				this.depuration.start = false
				this.depuration.selecteds = []
				this.depuration.show = false
				this.showToast(
					"success",
					"top-right",
					"Corrales",
					"CheckIcon",
					"La solicitud de depuración se realizo correctamente"
				)
			} catch (error) {
				console.log(error)
				this.showToast("danger", "top-right", "Corrales", "InfoIcon", error.message)
			} finally {
				this.isPreloading(false)
			}
		},
		showMachos(macho) {
			if (!(this.changeCorral.selecting && macho.status == 5) && !this.retirement.start && !this.depuration.start)
				return true
			if (macho.status == 2 && !macho.specimen_id && macho.emplaced == 0) return true
			return false
		},
		showHembras(hembra) {
			if (
				!(this.changeCorral.selecting && hembra.status == 5) &&
				!this.retirement.start &&
				!this.depuration.start
			)
				return true
			if (hembra.status == 2 && !hembra.specimen_id) return true
			return false
		},
		cancelDepuration() {
			this.depuration.start = false
			this.depuration.selecteds = []
			this.depuration.show = false
			this.posturas.forEach((item) => {
				item.machos = item.machos.map((it) => {
					return {
						...it,
						check: false,
					}
				})
				item.hembras = item.hembras.map((it) => {
					return {
						...it,
						check: false,
					}
				})
				item.sin_asignar = item.sin_asignar.map((it) => {
					return {
						...it,
						check: false,
					}
				})
			})
		},
		openCorralSelector(type) {
			this.showCorralSelector = true
			this.sex = type
		},
		async changeCorralByType({ corral_id, sex, corral_name }) {
			try {
				const result = this.posturas
					.filter((item) => item[sex].length > 0)
					.flatMap((item) =>
						item[sex].map((chick) => ({
							id: chick.id,
							new_corral_id: corral_id,
						}))
					)
				const resultLength = result.length
				if (resultLength == 0) {
					this.showToast("warning", "top-right", "Corrales", "InfoIcon", "No hay pollos para cambiar")
					return
				}
				const { isConfirmed } = await this.showConfirmSwal({
					title: "¿Estás seguro de cambiar corrales?",
					html: `
						<div>
						<p><strong>Cantidad:</strong> ${resultLength}</p>
						<p><strong>Sexo:</strong> ${this.sexName[sex]}</p>
						<p><strong>Origen:</strong> ${this.modalViewChicks.title}</p>
						<p><strong>Destino:</strong> ${corral_name}</p>
						</div>
					`,
				})
				if (!isConfirmed) return
				this.isPreloading()
				await CorralService.changeCorralChicks({ chicks: result })
				await this.init()
				this.showToast(
					"success",
					"top-right",
					"Corrales",
					"CheckIcon",
					"El cambio de corrales se realizo correctamente"
				)
			} catch (error) {
				this.showToast("danger", "top-right", "Corrales", "AlertCircleIcon", error.message)
			} finally {
				this.showCorralSelector = false
				this.isPreloading(false)
			}
		},
		existsInSaved(id) {
			return this.savedChicks.some((item) => item.id === id)
		},
		async finalize() {
			const { isConfirmed } = await this.showConfirmSwal()
			if (!isConfirmed) return

			this.isPreloading()

			try {
				const res = await chicksService.updateGenderMassively({
					chicks: this.savedChicks.map((el) => ({
						id: el.id,
						gender: 2,
					})),
				})
				await this.init()
				this.savedChicks = []
				this.showSuccessToast(res.message)
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},
		async removeChickenForCorral(chicken, type, index) {
			this.chicksDeleted.push(chicken)
			if (type == "hembras") {
				const newFemaleChicks = this.savedChicks.filter((it) => it.id != chicken.id)
				this.savedChicks = [...newFemaleChicks]
			}
			this.posturas[index][type] = this.posturas[index][type].map((item) => {
				if (item.id == chicken.id) {
					return {
						...item,
						visible: false,
					}
				} else {
					return {
						...item,
					}
				}
			})
		},
		async init() {
			//
			this.isPreloading()
			this.hembraDropdownName = "Por # de hembras"
			const { message, posturas } = await CorralService.getPosturas({
				corral_id: this.corral.id,
				search: this.search,
			})
			this.posturas = posturas
			this.reloadChicks()
			this.machos_qty = this.posturas.map((it) => it.machos.length).reduce((a, b) => a + b, 0)
			this.hembras_qty = this.posturas.map((it) => it.hembras.length).reduce((a, b) => a + b, 0)
			this.sin_asignar_qty = this.posturas.map((it) => it.sin_asignar.length).reduce((a, b) => a + b, 0)
			this.chicksLength = this.posturas
				.map((it) => {
					return {
						sum:
							it.machos.filter((it) => it.visible == true && !it.specimen_id && it.status == 2).length +
							it.hembras.filter((it) => it.visible == true && !it.specimen_id && it.status == 2).length +
							it.sin_asignar.filter((it) => it.visible == true && !it.specimen_id && it.status == 2)
								.length,
					}
				})
				.reduce(function (previousValue, currentValue) {
					return previousValue + currentValue.sum
				}, 0)
			this.specimensLength = this.posturas
				.map((it) => {
					return {
						sum:
							it.machos.filter((it) => it.specimen_id).length +
							it.hembras.filter((it) => it.specimen_id).length +
							it.sin_asignar.filter((it) => it.specimen_id).length,
					}
				})
				.reduce(function (previousValue, currentValue) {
					return previousValue + currentValue.sum
				}, 0)

			this.modalViewChicks.title = `Corral ${this.corral.code}`
			if (this.corral.alias) this.modalViewChicks.title += ` / ${this.corral.alias}`
			this.updateDate = false

			// vuelve a marcar los que estaban selecionados para cambio de placa
			if (this.retirement.selects.length > 0) {
				this.retirement.selects.forEach((chick) => {
					this.refreshCorrales(chick, true)
				})
			}
			this.reassignCorralChanged()
			this.sortPosturas(1) // initial sort by padrillo
			this.isPreloading(false)
		},
		async updateChicks() {
			const { isConfirmed } = await this.showConfirmSwal()
			if (!isConfirmed) return
			const { message } = await CorralService.update({
				corral_id: this.corral.id,
				chicks: this.chicksDeleted.map((it) => {
					return {
						...it,
						ca: it.correlative,
					}
				}),
				start_date: this.date,
			})
			this.showToast("success", "top-right", "Corrales", "AlertCircleIcon", message)
			this.chicksDeleted = []
			await this.init()
		},
		changeDate() {
			this.updateDate = true
		},
		async changeChick(e, type, index) {
			this.posturas[index][`${type}_reload`] = true

			if (e && e.added) {
				let key = this.posturas[index]

				let postura = this.posturas[index]

				let chicks = postura.chicks.map((it) => {
					return {
						...it,
						gender:
							it.id == e.added.element.id && index == e.added.element.key
								? type == "machos"
									? 1
									: type == "hembras"
									? 2
									: 3
								: it.gender,
					}
				})

				this.posturas[index].chicks = chicks

				this.posturas = this.posturas.map((item, indexAux) => {
					return {
						...item,
						machos: item.chicks
							.filter((item) => item.gender == 1)
							.map((it) => {
								return {
									...it,
									visible: true,
									key: indexAux,
									type: "machos",
								}
							}),
						hembras: item.chicks
							.filter((item) => item.gender == 2)
							.map((it) => {
								return {
									...it,
									visible: true,
									key: indexAux,
									type: "hembras",
								}
							}),
						sin_asignar: item.chicks
							.filter((item) => item.gender == 3)
							.map((it) => {
								return {
									...it,
									visible: true,
									key: indexAux,
									type: "sin_asignar",
								}
							}),
					}
				})

				if (e.added.element.key == index) {
					if (e.added.element.specimen_id) {
						await this.init()
						return this.showToast(
							"warning",
							"top-right",
							"Corrales",
							"AlertCircleIcon",
							"No se puede cambiar de genero a un ejemplar"
						)
					} else {
						// if (type === "hembras") {
						// 	const existChick = this.savedChicks.find((c) => c.id === e.added.element.id)

						// 	if (!existChick) {
						// 		this.savedChicks.push({ id: e.added.element.id, gender: 2 })
						// 	}
						// } else {
						console.log(e.added.element)
						const filteredChicks = this.savedChicks.filter((c) => c.id !== e.added.element.id)
						this.savedChicks = filteredChicks

						const { message, status } = await chicksService.updateGenderMassively({
							chicks: [
								{
									id: e.added.element.id,
									gender: type == "machos" ? 1 : type == "hembras" ? 2 : 3,
								},
							],
						})

						if (status) {
							this.showToast(
								"success",
								"top-right",
								"Corrales",
								"CheckIcon",
								"Cambio realizado correctamente"
							)
							await this.init()
						} else {
							await this.init()
							this.showToast("danger", "top-right", "Corrales", "AlertCircleIcon", message)
						}
						// }
					}
				} else {
					this.showToast(
						"info",
						"top-right",
						"Corrales",
						"AlertCircleIcon",
						"No se puede cambiar del nucleo familiar al cintillo."
					)
				}
			}

			// if (e && e.removed) {
			// 	this.posturas[index][`${type}_reload`] = true;
			// }

			setTimeout(() => {
				this.posturas[index][`${type}_reload`] = false
			}, 1000)
		},
		async updateColumnsCorral() {
			if (!(await this.$refs.form.validate())) {
				this.showToast(
					"danger",
					"top-right",
					"Campos Incompletos",
					"AlertCircleIcon",
					"Faltan campos por llenar"
				)
				return
			}

			const { isConfirmed } = await this.showConfirmSwal()
			if (!isConfirmed) return

			this.isPreloading()
			try {
				const { message, status } = await CorralService.updateColumnsCorral({
					alias: this.alias,
					comment: this.comment,
					start_date: this.date,
					id: this.corral.id,
				})
			} catch (error) {}
			this.isPreloading(false)

			this.updateDate = false
		},
		addRetirementChick({ chicken, key, type, retirement_check }) {
			if (retirement_check) {
				this.retirement.selects.push({ ...chicken, plate: null, image: null, check_deworm: false })
			} else {
				const index = this.retirement.selects.findIndex((it) => it.id == chicken.id)
				this.retirement.selects.splice(index, 1)
			}
			this.posturas[key][type] = this.posturas[key][type].map((item) => {
				if (item.id == chicken.id) {
					return {
						...item,
						check: retirement_check,
					}
				} else {
					return {
						...item,
					}
				}
			})
		},
		deleteAddRetirementChick(chick) {
			this.retirement.selects = this.retirement.selects.filter((it) => it.id != chick.id)
		},
		async refreshRetirementChicks(repeat) {
			await this.init()
			this.isPreloading(false)
			this.retirement.selects = this.retirement.selects.filter((it) => repeat.includes(it.plate))

			if (this.retirement.selects.length == 0) {
				this.retirement.show = false
				this.retirement.start = false
				this.showToast("success", "top-right", "Retiro", "AlertCircleIcon", "Placas agregadas correctamente.")
			}
		},
		refreshCorrales(chick, check) {
			const index = this.posturas.findIndex((it) => {
				let inRe = false
				const chicks = it.chicks
				chicks.forEach((element) => {
					if (element.id == chick.id) {
						inRe = true
					}
				})
				return inRe
			})

			if (index != -1) {
				this.posturas[index][chick.type] = this.posturas[index][chick.type].map((item) => {
					if (item.id == chick.id) {
						return {
							...item,
							check: check,
						}
					} else {
						return {
							...item,
						}
					}
				})
			}
		},
		async cancelRetirement() {
			this.retirement.start = false

			// await this.init();
			if (this.retirement.selects.length > 0) {
				this.retirement.selects.forEach((chick) => {
					this.refreshCorrales(chick, false)
				})
			}
			this.retirement.selects = []
		},
		async filterSick() {
			await this.init()
			this.posturas = this.posturas
				.map((ck) => {
					const chicks = ck.chicks.filter((c) => c.status == 5)
					return {
						...ck,
						chicks,
					}
				})
				.filter((ck) => ck.chicks.length != 0)

			this.reloadChicks()
			this.filtersActive = true
		},
		async filterDead() {
			await this.init()
			this.posturas = this.posturas
				.map((ck) => {
					const chicks = ck.chicks.filter((c) => [3, 4].includes(c.status))
					return {
						...ck,
						chicks,
					}
				})
				.filter((ck) => ck.chicks.length != 0)

			this.reloadChicks()
			this.filtersActive = true
		},
		async filterAvailable() {
			await this.init()
			this.posturas = this.posturas
				.map((ck) => {
					const chicks = ck.chicks.filter((c) => 2 == c.status && c.specimen_id == null)
					return {
						...ck,
						chicks,
					}
				})
				.filter((ck) => ck.chicks.length != 0)

			this.reloadChicks()
			this.filtersActive = true
		},
		async hembrasFilter(type) {
			await this.init()
			this.posturas = this.posturas.filter((ck) => {
				if (type == 2) {
					this.hembraDropdownName = "= 2 hembras"
					return ck.hembras.length == 2
				}
				if (type == 3) {
					this.hembraDropdownName = "= 3 hembras"
					return ck.hembras.length == 3
				}
				if (type == 4) {
					this.hembraDropdownName = ">= 4 hembras"
					return ck.hembras.length >= 4
				}
				return ck.hembras.length >= 0
			})
			this.reloadChicks()
			this.filtersActive = true
		},
		async filterPlates() {
			await this.init()
			this.posturas = this.posturas
				.map((ck) => {
					const chicks = ck.chicks.filter((c) => 2 == c.status && c.specimen_id)
					return {
						...ck,
						chicks,
					}
				})
				.filter((ck) => ck.chicks.length != 0)
			this.reloadChicks()
			this.filtersActive = true
		},
		reloadChicks() {
			this.posturas = this.posturas.map((item, index) => {
				return {
					...item,
					machos: item.chicks
						.filter((item) => item.gender == 1)
						.map((it) => {
							return {
								...it,
								visible: true,
								key: index,
								type: "machos",
								check: false,
							}
						}),
					hembras: item.chicks
						.filter((item) => item.gender == 2)
						.map((it) => {
							return {
								...it,
								visible: true,
								key: index,
								type: "hembras",
								check: false,
							}
						}),
					sin_asignar: item.chicks
						.filter((item) => item.gender == 3)
						.map((it) => {
							return {
								...it,
								visible: true,
								key: index,
								type: "sin_asignar",
								check: false,
							}
						}),
					machos_reload: false,
					hembras_reload: false,
					sin_asignar_reload: false,
					key: index,
				}
			})
		},
		sortPosturas(type) {
			let withLetter
			let withNumber
			switch (type) {
				case 1: //desc padrillo
					this.posturas = this.posturas.sort((str1, str2) =>
						str1.padrillo.alias.localeCompare(str2.padrillo.alias)
					)
					this.curSort.padrillo = true
					break
				case 2: //asc padrillo
					this.posturas = this.posturas.sort((str1, str2) =>
						str2.padrillo.alias.localeCompare(str1.padrillo.alias)
					)
					this.curSort.padrillo = false
					break
				case 3: //desc madrilla
					withLetter = this.posturas.filter((postura) => !/^\d+$/.test(postura.madrilla.plate))
					withNumber = this.posturas.filter((postura) => /^\d+$/.test(postura.madrilla.plate))
					withNumber.sort((pos1, pos2) => Number(pos2.madrilla.plate) - Number(pos1.madrilla.plate))
					this.posturas = [...withLetter, ...withNumber]
					this.curSort.madrilla = true
					break
				case 4: //asc madrilla
					withLetter = this.posturas.filter((postura) => !/^\d+$/.test(postura.madrilla.plate))
					withNumber = this.posturas.filter((postura) => /^\d+$/.test(postura.madrilla.plate))
					withNumber.sort((pos1, pos2) => Number(pos1.madrilla.plate) - Number(pos2.madrilla.plate))
					this.posturas = [...withNumber, ...withLetter]
					this.curSort.madrilla = false
					break
			}
		},
		toogleModalSiblings(postura) {
			this.modalSiblingParents = {
				padrillo_id: postura.padrillo_id,
				madrilla_id: postura.madrilla_id,
			}
			this.showModalSiblings = !this.showModalSiblings
		},
		handleChickPlateClick(chick, posturaIdx, type) {
			if (this.depuration.start) {
				if (!chick.has_request) {
					console.log("UWU")
					if (chick.check) {
						this.depuration.selecteds = this.depuration.selecteds.filter((c) => c.id != chick.id)
					} else {
						this.depuration.selecteds.push(chick)
					}
					chick.check = !chick.check
				}
			}
			if (this.retirement.start) {
				let strType = ""
				switch (type) {
					case 1:
						strType = "machos"
						break
					case 2:
						strType = "hembras"
						break
					case 3:
						return
				}
				this.addRetirementChick({
					chicken: chick,
					key: posturaIdx,
					type: strType,
					retirement_check: !chick.check,
				})
			}
			if (this.changeCorral.selecting) {
				// partial se usa para cuando se hace una busqueda los cintillos queden marcados
				if (chick.check) {
					const idx = this.changeCorral.partial.findIndex((c) => c.id == chick.id)
					if (idx != -1) this.changeCorral.partial.splice(idx, 1)
				} else {
					this.changeCorral.partial.push(chick)
				}
				chick.check = !chick.check
			}
		},
		processChangeCorral() {
			const chicks = []
			this.posturas.forEach((p) => {
				p.hembras.forEach((c) => {
					if (c.check) chicks.push(c)
				})
				p.machos.forEach((c) => {
					if (c.check) chicks.push(c)
				})
				p.sin_asignar.forEach((c) => {
					if (c.check) chicks.push(c)
				})
			})
			if (chicks.length == 0) {
				this.showToast("warning", "top-right", "Corrales", "InfoIcon", "Debes seleccionar al menos 1 cintillo")
				return
			}
			// changeCorral: { show: false, chicks: [], selecting: false },
			this.changeCorral.info = { chicks, corral: this.corral }
			this.changeCorral.show = !this.changeCorral.show
			this.changeCorral.selecting = false
			this.cancelChangeCorral()
		},
		reassignCorralChanged() {
			if (this.changeCorral.partial.length > 0) {
				const selecteds = this.changeCorral.partial.map((chick) => chick.id)
				this.posturas.forEach((postura, idx) => {
					postura.machos.forEach((m) => {
						if (selecteds.includes(m.id)) m.check = true
					})
					postura.hembras.forEach((h) => {
						if (selecteds.includes(h.id)) h.check = true
					})
					postura.sin_asignar.forEach((sa) => {
						if (selecteds.includes(sa.id)) sa.check = true
					})
				})
			}
		},
		cancelChangeCorral() {
			this.posturas.forEach((p) => {
				p.hembras.forEach((c) => {
					if (c.check) c.check = false
				})
				p.machos.forEach((c) => {
					if (c.check) c.check = false
				})
				p.sin_asignar.forEach((c) => {
					if (c.check) c.check = false
				})
			})
			this.changeCorral.partial = []
			this.changeCorral.selecting = false
		},
		async applyDeworn() {
			const { isConfirmed } = await this.showConfirmSwal({
				title: `Se realizara una desparacitacion a todos los pollos del corral ${
					this.corral.alias || this.corral.code
				}`,
			})
			if (!isConfirmed) return
			this.isPreloading()
			await CorralService.insertMassiveDeworn({ corral_id: this.corral.id })

			await this.init()
			this.isPreloading(false)
			// this.init()
			this.showToast(
				"success",
				"top-right",
				"Corrales",
				"CheckIcon",
				"Registro de desparacitacion insertado correctamente"
			)
		},
		async removeAllChicks() {
			const { isConfirmed } = await this.showConfirmSwal({
				title: `Se retiraran todos los pollos del corral ${this.corral.alias || this.corral.code}`,
			})
			if (!isConfirmed) return
			this.isPreloading()
			await CorralService.removeAllChicks({ corral_id: this.corral.id })
			this.isPreloading(false)
			this.filterAvailable()
			this.showToast(
				"success",
				"top-right",
				"Corrales",
				"CheckIcon",
				`Se retiraron los pollos del corral ${this.corral.alias || this.corral.code}`
			)
		},
	},
}
</script>

<style lang="scss" scoped>
.summary-badges {
	display: flex;
	justify-content: center;
	gap: 10px;
	margin-bottom: 10px;
}

.badge {
	padding: 5px 10px;
	border-radius: 10px;
	font-size: 14px;
	font-weight: bold;
	color: white;
}

.badge-male {
	background-color: #007bff; /* Azul */
}

.badge-female {
	background-color: #e83e8c; /* Rosa */
}

.badge-unassigned {
	background-color: #6c757d; /* Gris */
}
.chick-grid {
	display: flex;
	background: #fafafa;
	flex-wrap: wrap;
	padding: 1.2rem;
	min-height: 100px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
	justify-content: space-around;
	align-items: center;
	flex-direction: row;
	width: 240px !important;
	.chick-item {
		// background: #7367f0;
		width: auto;
		position: relative;
		padding: 0.3rem 0.6rem;
		border-radius: 4px;
		margin-right: 1rem;
		margin-bottom: 0.25rem;
		margin-top: 0.25rem;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 20;
		// &:last-child {
		// 	margin-bottom: 0;
		// 	margin-right: 0;
		// }
		p {
			color: #fff;
			font-weight: bolder;
		}
		.close {
			// z-index: 100;
			position: absolute;
			top: -10px;
			right: -10px;
			background: rgb(255, 51, 1);
			height: 20px;
			width: 20px;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			transition: 0.5s all ease-in-out;
			cursor: pointer;
			.icon {
				color: #fff;
			}
		}
		.circle-check-cintillo {
			margin-left: 0.5rem;
			height: 20px;
			width: 20px;
			// background: red;
			border: 2px solid #fff;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}
.container-header {
	margin: 1rem 0;
	display: flex;
	flex-direction: column;
	@media screen and (min-width: 500px) {
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}
	.select-year {
		@media screen and (min-width: 500px) {
			width: 40% !important;
		}
		@media screen and (min-width: 1000px) {
			width: 50% !important;
		}
	}
	.create-corral {
		margin-top: 1rem;
		@media screen and (min-width: 500px) {
			width: 40% !important;
			margin-top: 0;
		}
		@media screen and (min-width: 1000px) {
			width: 25% !important;
		}
	}
}

.container-cantidad-pollos {
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	padding: 0rem 1.5rem 1rem 1rem;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	p {
		padding: 0.2rem 0.5rem;
		background: #7367f0;
		border-radius: 4px;
		width: auto !important;
		color: #fff;
		font-weight: bold;
		margin-top: 0;
		margin-bottom: 0;
	}
}

.button {
	margin-top: 35px;
}
.flip-list-move {
	transition: transform 0.5s;
}
.no-move {
	transition: transform 0s;
}
.ghost {
	opacity: 0.5;
	background: #c8ebfb;
}
.list-group-corrales {
	min-height: 100px;
}
.list-group-corrales-item {
	cursor: pointer;
}

.sin-chicks {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 10;
}
.z-20 {
	z-index: 200 !important;
}
.bg-skeletor-reload-chicks {
	background: #f6f6f6;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	position: absolute;
	// z-index: 100;
	display: none;
	// display: flex;
	opacity: 0;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	transition: 0.2s all ease-in-out;
}
.active-skeletor {
	// display: flex;
	z-index: 100;
	opacity: 1;
}
.bg-dead {
	background: var(--danger);
}
.bg-specimens {
	background: #28c76f;
}
.circle {
	width: 12px;
	border-radius: 50%;
	height: 12px;
}

.text-span-corrales {
	font-size: 13px !important;
	@media screen and (min-width: 700px) {
		font-size: 18px !important;
	}

	@media screen and (min-width: 1000px) {
		font-size: 22px !important;
	}
}
.container-drop-retiro {
	z-index: 50;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;
	@media screen and (min-width: 600px) {
		flex-direction: row;
	}
	.btn-group {
		width: 100%;
		@media screen and (min-width: 600px) {
			width: auto;
			// margin-right: 1rem;
		}
	}
	.search-container-value {
		display: flex;
		align-items: center;
		margin-top: 1rem;
		width: 100%;
		@media screen and (min-width: 600px) {
			margin-top: 0;
			width: auto;
			// padding-left: 1rem !important;
			// background: red;
		}
		.search-input {
			flex: 1;
			// background: red;
		}
		.search-btn {
			margin-left: 1rem !important;
		}
	}
}

// .close {
// 	// z-index: 100;
// 	position: absolute;
// 	top: -10px;
// 	right: -10px;
// 	background: rgb(255, 51, 1);
// 	height: 20px;
// 	width: 20px;
// 	border-radius: 50%;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// 	transition: 0.5s all ease-in-out;
// 	cursor: pointer;
// 	.icon {
// 		color: #fff;
// 	}
// }
.tracking-corrales {
	position: absolute;
	right: -10px;
	bottom: -10px;
	height: 20px;
	width: 20px;
	background: rgb(40, 199, 111);
	opacity: 0.5;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.bordered {
	border: 2px solid #fd9a53 !important;
}
</style>
