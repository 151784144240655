<template>
	<b-modal
		ref="chick-depuration-details-modal"
		:title="`Pollos para depurar del corral (${code_corral} / ${alias_corral})`"
		centered
		v-model="isActive"
		@hidden="handleHidden"
		size="xlg"
		hide-footer
	>
		<b-overlay :show="isLoading">
			<div class="d-flex pb-1">
				<b-badge variant="primary">
					<span>Total: {{ chicks.length }}</span>
				</b-badge>
			</div>
			<ValidationObserver ref="form">
				<b-table-simple responsive sticky-header="60vh" no-border-collapse>
					<b-thead class="sticky-header position-relative" style="z-index: 20">
						<b-th class="text-center"></b-th>
						<b-th class="text-center">Postura</b-th>
						<b-th class="text-center">Madrilla</b-th>
						<b-th class="text-center">Padrillo</b-th>
						<b-th class="text-center">Cintillo</b-th>
						<b-th class="text-center">Observación</b-th>
						<b-th class="text-center">Evidencia</b-th>
						<b-th class="text-center">DEPURADO POR</b-th>
					</b-thead>
					<b-tbody>
						<template v-if="chicks.length > 0">
							<b-tr v-for="(chick, index) in chicks" :key="index">
								<b-td>
									<div class="d-flex justify-content-center postion-relative" style="z-index: 1">
										<b-form-checkbox
											v-model="chick.is_check"
											:disabled="chick.status_id == 4"
										></b-form-checkbox>
									</div>
								</b-td>
								<b-td class="text-center">
									<span>{{ chick.postura_code }}</span>
								</b-td>
								<b-td>
									<SpecimenPlate :specimen="chick.madrilla" />
								</b-td>
								<b-td>
									<SpecimenPlate :specimen="chick.padrillo" />
								</b-td>
								<b-td class="text-center">
									<span>{{ chick.correlative }}</span>
								</b-td>
								<b-td>
									<b-form-textarea
										style="min-width: 12rem"
										no-resize
										v-model="chick.comentario"
										:disabled="chick.status_id == 4"
									/>
								</b-td>
								<b-td>
									<ValidationProvider :rules="{ required: chick.is_check }" v-slot="{ errors }">
										<div
											v-if="!chick.useEvidence"
											class="d-flex justify-content-center align-items-center"
										>
											<b-button
												class="btn-icon text-center"
												:class="{ 'border-danger rounded': !!errors[0] }"
												variant="flat-secondary"
												@click="chick.useEvidence = true"
											>
												<feather-icon icon="PlusIcon" size="20" />
											</b-button>
										</div>
										<div
											class="d-flex justify-content-center align-items-center gap-x-2"
											style="min-width: 12rem"
											v-else
										>
											<ShowAndUploadImg
												v-model="chick.evidencia"
												:readonly="chick.status_id == 4"
											/>
											<b-button
												v-if="chick.status_id != 4"
												class="btn-icon"
												variant="flat-danger"
												@click="hideEvidence(index)"
											>
												<feather-icon
													icon="TrashIcon"
													size="20"
													@click="chick.useEvidence = true"
												/>
											</b-button>
										</div>
									</ValidationProvider>
								</b-td>
								<b-td>
									<template v-if="chick.status_id == 4">
										<div class="text-center">{{ chick.depurated_by }}</div>
										<div class="text-center">{{ chick.depurated_at | myGlobalDateTime }}</div>
									</template>
								</b-td>
							</b-tr>
						</template>
						<b-tr v-if="chicks.length == 0">
							<b-td colspan="10" class="text-center" style="height: 80px">
								No hay registros para mostrar
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>
			</ValidationObserver>
			<div class="d-flex w-100 justify-content-end align-items-center gap-x-4" style="padding-inline: 1rem">
				<b-button @click="save" variant="primary" :disabled="getCountMod === 0">
					DEPURAR
					<b-badge v-if="getCountMod > 0" variant="danger">{{ getCountMod }}</b-badge>
				</b-button>
			</div>
		</b-overlay>
	</b-modal>
</template>

<script>
import modalMixin from "@/mixins/modal"
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import ViewImage from "@/components/commons/ViewImage.vue"
import reasonsService from "@/services/reasons.service"
import deadsService from "@/services/deads.service"
import CorralService from "@/services/encaste/corral.service"
import axios from "@/axios"
import { getBase64 } from "@/helpers/functions"
import { mapActions } from "vuex"
import ShowAndUploadImg from "@/views/amg/deads/v2/ShowAndUploadImg.vue"
export default {
	components: { SpecimenPlate, ViewImage, ShowAndUploadImg },
	mixins: [modalMixin],
	props: {
		show: Boolean,
		info: Object,
		corral_id: Number,
		alias_corral: String,
		code_corral: String,
		request_id: Number,
	},
	data() {
		return {
			isActive: false,
			isLoading: false,
			chicks: [],
		}
	},
	async created() {
		await this.getChicks()
	},
	computed: {
		getCountMod() {
			return this.chicks.filter((it) => it.is_check).length
		},
	},
	methods: {
		async hideEvidence(idx) {
			const row = this.chicks[idx]
			if (row.evidencia.length != 0) {
				const { isConfirmed } = await this.showConfirmSwal({
					text: `Se borraran las imagenes de evidencia seleccionadas`,
				})
				if (!isConfirmed) return
				row.evidencia = []
			}
			row.useEvidence = false
		},
		...mapActions({
			A_GET_FEMALE_FOR_DEPURATION_COUNTER: "commons/A_GET_FEMALE_FOR_DEPURATION_COUNTER",
		}),
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.$emit("closing")
		},
		async getChicks() {
			this.isPreloading()
			try {
				const { data } = await CorralService.getDepurationRequestDetails(this.request_id)
				this.chicks = data.map((it) => {
					return {
						...it,
						is_check: false,
						evidencia: [...it.evids],
						useEvidence: it.evids.length > 0,
						comentario: it.observation || "",
					}
				})
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},

		async save() {
			if (!(await this.$refs.form.validate())) return
			const { isConfirmed } = await this.showConfirmSwal({
				title: `Se registrara ${this.getCountMod} cintillo(s) en ala como DEPURADO`,
			})
			if (!isConfirmed) return

			const cintillos = []
			for (let i = 0; i < this.chicks.length; i++) {
				const row = this.chicks[i]
				let images = []
				for (let idx = 0; idx < row.evidencia.length; idx++) {
					const img = row.evidencia[idx]
					let base = undefined
					if (img.id == null) {
						base = await getBase64(img.file)
					}
					images.push({ base })
				}
				if (row.is_check) {
					cintillos.push({
						id: row.id,
						status_id: 4,
						reason_id: undefined,
						observation: row.comentario || null,
						images,
					})
				}
			}
			try {
				this.isPreloading(true)
				await CorralService.depurateChicks({ chicks: cintillos, request_id: this.request_id })
				await this.A_GET_FEMALE_FOR_DEPURATION_COUNTER()
				await this.getChicks()
				this.$emit("refresh")
				this.showToast("success", "top-right", "Depurados", "CheckIcon", "Guardado con exito")
			} catch (error) {
				console.log(error)
				this.showToast("error", "top-right", "Depurados", "XIcon", "Error al guardar")
			} finally {
				this.isPreloading(false)
			}
		},
	},
	watch: {},
	async mounted() {
		this.toggleModal("chick-depuration-details-modal")
	},
}
</script>

<style lang="scss" scoped></style>
