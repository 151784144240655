export default [
	{
		title: "Administrativo",
		icon: "ArchiveIcon",
		roles: [1, 6],
		children: [
			{
				title: "Caja chica",
				route: "management-petty-cash",
				requiresAdmin: true,
				requiresInvitado: true,
				requiresEncastador: false,
			},
			{
				title: "Productos",
				route: "management-warehouse",
				requiresAdmin: true,
				requiresInvitado: true,
				requiresEncastador: false,
			},
			// {
			//     title: 'Dashboard',
			//     route: 'administrativo-dashboard-sr',
			//     requiresAdmin: true,
			//     requiresInvitado: true,
			//     requiresEncastador: false
			// },
			// {
			//     title: 'Gastos',
			//     route: 'administrativo-gastos-sr',
			//     requiresAdmin: true,
			//     requiresInvitado: true,
			//     requiresEncastador: true
			// },
			// {
			//     title: 'Depósitos',
			//     route: 'administrativo-depositos-sr',
			//     requiresAdmin: true,
			//     requiresInvitado: true,
			//     requiresEncastador: false
			// },
			// {
			//     title: 'Empleados',
			//     route: 'administrativo-empleados-sr',
			//     requiresAdmin: true,
			//     requiresInvitado: true,
			//     requiresEncastador: false
			// }
		],
		requiresAdmin: true,
		requiresInvitado: true,
		requiresEncastador: true,
	},
]
